import React, { Fragment, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    checkUrlExistInFileApp,
    FileInterface,
    generateActivityFiles,
    uploadFileUrlToStorage,
} from './downloadFileHelpers';
import Downloading from './components/Downloading';
import Downloaded from './components/Downloaded';
import DownloadError from './components/DownloadError';
import Header from './components/Header';
import './Download.scss';

interface PayloadInterface {
    fileName: string;
    fileUrl: string;
    zipName: string;
    activityId: string;
    indexes: string;
}

function DownLoadFile() {
    const [isDownloading, setIsDownloading] = useState(true);
    const [error, setError] = useState('');
    const { encryptCode }: any = useParams();
    const decodedData = decodeURIComponent(window.atob(encryptCode));
    const payload: PayloadInterface = JSON.parse(decodedData);
    const { fileName, fileUrl, zipName, activityId, indexes } = payload;

    useEffect(() => {
        if (fileName && fileUrl) downloadSingleFile(fileName, fileUrl);
        else downloadZip();
    }, []);

    const downloadSingleFile = async (fileName: string, fileUrl: string) => {
        if (!checkUrlExistInFileApp(fileUrl)) fileUrl = await uploadFileUrlToStorage(fileUrl);
        fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                setIsDownloading(false);
            })
            .catch(console.error);
    };

    const downloadZip = async () => {
        const filesAndUrls = await generateActivityFiles(activityId, indexes);
        checkAndUpdateFileUrls(filesAndUrls).then((allUpdatedFileUrls: any) => {
            if (allUpdatedFileUrls.length === 0) {
                setIsDownloading(false);
                setError('No file can be downloaded successfully, please check your network.');
                return;
            }

            var zip = new JSZip();
            let successfulCount = 0;
            let failedCount = 0;

            allUpdatedFileUrls.forEach(async (item: FileInterface) => {
                Axios.get(item.url, {
                    responseType: 'blob',
                })
                    .then((response: any) => {
                        zip.file(item.fileName, response.data, {
                            binary: true,
                        });
                        // console.log(`Successful: ${item.url}`);
                        ++successfulCount;
                        ifEndDownload();
                    })
                    .catch((error: any) => {
                        // console.log(error);
                        // console.log(`Failed: ${item.url}`);
                        ++failedCount;
                        ifEndDownload();
                    });
            });

            const ifEndDownload = () => {
                if (successfulCount + failedCount === filesAndUrls.length) {
                    if (successfulCount > 0) {
                        zip.generateAsync({
                            type: 'blob',
                        }).then(function (content) {
                            saveAs(content, zipName + '.zip');
                        });
                        setIsDownloading(false);
                    } else {
                        setIsDownloading(false);
                        setError('No file can be downloaded successfully, please check your network.');
                    }
                }
            };
        });
    };

    const checkAndUpdateFileUrls = async (filesAndUrls: FileInterface[]) => {
        return new Promise(async (resolve, reject) => {
            const updatedFileUrls: FileInterface[] = [];
            await Promise.all([
                ...filesAndUrls.map(async (item) => {
                    let fileUrl;
                    if (item.isImageUploadActivity && !checkUrlExistInFileApp(item.url))
                        fileUrl = await uploadFileUrlToStorage(item.url);
                    else fileUrl = item.url;
                    if (fileUrl) {
                        updatedFileUrls.push({
                            fileName: item.fileName,
                            url: fileUrl,
                        });
                    }
                }),
            ]).then(() => {
                resolve(updatedFileUrls);
            });
        });
    };

    return (
        <Fragment>
            <Header />
            {isDownloading ? <Downloading /> : !error ? <Downloaded /> : <DownloadError error={error} />}
        </Fragment>
    );
}

export default DownLoadFile;
