import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import quizApi from '../services/quizApi';
import { populateRankedParticipants, QuizSummaryInterface } from './helpers/quizSummaryHelper';
import QuizActivityDataInterface from './interfaces/quiz-activity-data.interface';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Header from '../DownLoadFile/components/Header';
import Downloading from '../DownLoadFile/components/Downloading';
import Downloaded from '../DownLoadFile/components/Downloaded';
import DownloadError from '../DownLoadFile/components/DownloadError';
import { displayLocaleDate, InterfaceLangs, templateText } from './helpers/languageHelpers';

interface PayloadInterface {
    activityIds: string;
    lang: InterfaceLangs;
}

function QuizSummary() {
    const [quizSummary, setQuizSummary] = useState<QuizSummaryInterface | null>();
    const [averageTimeTakenArray, setaverageTimeTakenArray] = useState<any>([]);
    const [totalPoints, setTotalPoints] = useState<number>(0);
    const [isDownloading, setIsDownloading] = useState(true);
    const [error, setError] = useState('');

    const { encryptCode }: any = useParams();
    const decodedData = decodeURIComponent(window.atob(encryptCode));
    const payload: PayloadInterface = JSON.parse(decodedData);
    const { activityIds, lang } = payload;

    useEffect(() => {
        if (activityIds) loadQuizSummary();
    }, []);

    const secondsToTime = (value: number) => {
        const m = Math.floor(value / 60)
                .toString()
                .padStart(2, '0'),
            s = Math.floor(value % 60)
                .toString()
                .padStart(2, '0');
        return m + ':' + s;
    };

    const findImageDimension = (imageUrl: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.setAttribute('crossorigin', 'anonymous');
            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.style.border = '5px solid #foo';
                canvas.style.backgroundColor = '#222';
                let ctx: any = canvas.getContext('2d');
                let dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                ctx.beginPath();
                ctx.strokeStyle = '#BFBFBF'; // some color/style
                ctx.lineWidth = 1; // thickness
                ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                dataURL = canvas.toDataURL();
                resolve({
                    width: img.width,
                    height: img.height,
                    aspectRatio: img.width / img.height,
                    dataURL: dataURL,
                });
            };
        });
    };

    const loadQuizSummary = async () => {
        const rawData = await quizApi.getMultipleActivities(activityIds);
        // console.log(rawData);
        if (!rawData) {
            setError('Error loading quiz summary');
            return;
        }

        const quizDataArray: QuizActivityDataInterface[] = rawData.map((item: any) => ({
            activityId: item.activityId,
            activityCreatedOn: item.activityCreatedOn,
            activityResponses: item.activityResponses,
            activitySlideSavedUrl: item.activitySlideSavedUrl,
            mcCorrectAnswers: item.mcCorrectAnswers,
            correctPoints: item.correctPoints,
        }));

        const quizSummary = populateRankedParticipants(quizDataArray);

        const totalParticipants = quizSummary.rankedParticipants.length;
        const totalPointsValue = quizSummary.quizPropsArray
            .map((activity) => activity.correctPoints)
            .reduce((prev, next) => prev + next);
        setTotalPoints(totalPointsValue);
        let averageTimeTakenArrayValues: string[] = [];
        for (let x = 0; x < quizSummary.rankedParticipants[0].responses.length; x++) {
            let tempValue = 0;
            for (let y = 0; y < quizSummary.rankedParticipants.length; y++) {
                tempValue += quizSummary.rankedParticipants[y].responses[x]?.timeTaken || 0;
            }
            averageTimeTakenArrayValues.push(secondsToTime(tempValue / totalParticipants));
        }
        // console.log(quizSummary);
        setaverageTimeTakenArray(averageTimeTakenArrayValues);
        setQuizSummary(quizSummary);
    };

    useEffect(() => {
        if (averageTimeTakenArray.length > 0 && totalPoints) {
            exportExcel();
        }
    }, [averageTimeTakenArray, totalPoints]);

    const exportExcel = async () => {
        const quizData: any = quizSummary;

        // Excel File Properties
        const ExcelJSWorkbook = new ExcelJS.Workbook();

        ExcelJSWorkbook.creator = 'Inknoe ClassPoint';
        ExcelJSWorkbook.lastModifiedBy = 'Inknoe ClassPoint';
        ExcelJSWorkbook.created = new Date();
        ExcelJSWorkbook.modified = new Date();

        const quizSummarySheet = ExcelJSWorkbook.addWorksheet(templateText[lang].quiz_summary);

        // Determine Number of Questions
        let questionValues: any = [];
        const addQuestionHandler = () => {
            if (quizData.rankedParticipants[0].responses) {
                for (let n = 0; n < quizData.rankedParticipants[0].responses.length; n++) {
                    questionValues.push({ header: `Q${n + 1}`, key: `C_Q${n + 1}`, width: 15 });
                }
            } else {
                return questionValues.push({ header: `Q1`, key: `C_Q1`, width: 15 });
            }
        };
        addQuestionHandler();

        // Other Columns in the Worksheet
        const otherFields = [
            {
                header: templateText[lang].participant_name,
                key: 'A_sn',
                width: 5,
            },
            {
                header: '',
                key: 'B_name',
                width: 30,
            },
            {
                header: templateText[lang].total_stars_earned,
                key: 'D_TotalStarsEarned',
                width: 15,
            },
            {
                header: `${templateText[lang].score_percentage}\n(${templateText[lang].full_marks}: ${totalPoints})`,
                key: 'E_score',
                width: 15,
            },
            {
                header: `${templateText[lang].speed}\n(${templateText[lang].in_seconds})`,
                key: 'F_speed',
                width: 15,
            },
        ];

        // Create Columns
        const tempArray = questionValues.concat(otherFields);
        quizSummarySheet.columns = tempArray.sort((a: any, b: any) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));

        // Create Rows
        for (let row = 2; row < quizData.rankedParticipants.length + 6; row++) {
            const dataRow = quizSummarySheet.addRow({});
            for (let column = 0; column < quizSummarySheet.columnCount; column++) {
                let cell = dataRow.getCell(column + 1);
                cell.value = '';
            }
        }

        const rankedParticipantsSumHandler = (rowNumber: number, field: string) => {
            let tempValue = 0;
            for (let x = 0; x < quizData.rankedParticipants[rowNumber - 6].responses.length; x++) {
                if (
                    quizData.rankedParticipants[rowNumber - 6].responses[x] &&
                    quizData.rankedParticipants[rowNumber - 6].responses[x].isCorrect
                )
                    tempValue += quizData.rankedParticipants[rowNumber - 6].responses[x][field];
            }
            return tempValue;
        };

        // Fill Cells with Data
        quizSummarySheet.eachRow(function (row: any, _rowNumber: any) {
            row.eachCell(function (cell: any, _colNumber: any) {
                if (_rowNumber === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f0f2ff' },
                    };
                } else if (_rowNumber === 2) {
                    if (_colNumber === 1) {
                        cell.value = templateText[lang].difficulty_level;
                    } else if (_colNumber > 2 && _colNumber < quizSummarySheet.columnCount - 2) {
                        switch (quizData.quizPropsArray[_colNumber - 3].correctPoints) {
                            case 1: {
                                cell.value = '★☆☆';
                                break;
                            }
                            case 2: {
                                cell.value = '★★☆';
                                break;
                            }
                            case 3: {
                                cell.value = '★★★';
                                break;
                            }
                        }
                        cell.font = { color: { argb: 'ffc000' } };
                    }
                } else if (_rowNumber === 3) {
                    if (_colNumber === 1) {
                        cell.value = templateText[lang].correct_answers;
                    } else if (_colNumber > 2 && _colNumber < quizSummarySheet.columnCount - 2) {
                        cell.value = quizData.quizPropsArray[_colNumber - 3].mcCorrectAnswers.join(',');
                    }
                } else if (_rowNumber === 4) {
                    if (_colNumber === 1) {
                        cell.value = templateText[lang].correct_percentage;
                    } else if (_colNumber > 2 && _colNumber < quizSummarySheet.columnCount - 2) {
                        cell.value = quizData.quizPropsArray[_colNumber - 3].correctPercentage + '%';
                    }
                } else if (_rowNumber === 5) {
                    if (_colNumber === 1) {
                        cell.value = templateText[lang].average_time_taken;
                    } else if (_colNumber > 2 && _colNumber < quizSummarySheet.columnCount - 2) {
                        cell.value = averageTimeTakenArray[_colNumber - 3];
                    }
                } else {
                    if (_rowNumber > 5) {
                        if (_colNumber === 1) {
                            cell.value = _rowNumber - 5;
                        } else if (_colNumber === 2) {
                            cell.value = `   ${quizData.rankedParticipants[_rowNumber - 6].participantName}`;
                        } else if (_colNumber === quizSummarySheet.columnCount - 2) {
                            cell.value = rankedParticipantsSumHandler(_rowNumber, 'quizPoints');
                        } else if (_colNumber === quizSummarySheet.columnCount - 1) {
                            const totalQuizPoint = rankedParticipantsSumHandler(_rowNumber, 'quizPoints');
                            cell.value = totalQuizPoint / totalPoints;
                            cell.style = { numFmt: '0%' };
                        } else if (_colNumber === quizSummarySheet.columnCount) {
                            cell.value = quizData.rankedParticipants[_rowNumber - 6].averageTimeTaken || '';
                        }
                    }

                    if (_colNumber > 2 && _colNumber < quizSummarySheet.columnCount - 2) {
                        cell.value = quizData.rankedParticipants[_rowNumber - 6].responses[_colNumber - 3]
                            ? quizData.rankedParticipants[_rowNumber - 6].responses[_colNumber - 3].answer
                            : '---';
                        quizData.rankedParticipants[_rowNumber - 6].responses[_colNumber - 3] &&
                        quizData.rankedParticipants[_rowNumber - 6].responses[_colNumber - 3].isCorrect
                            ? (cell.font = { color: { argb: '00B050' } })
                            : (cell.font = { color: { argb: 'FF0000' } });
                    }
                }

                if (_colNumber !== 2 || (_colNumber === 2 && _rowNumber === 1)) {
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                } else {
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                }

                if (_rowNumber > 5 && !(_rowNumber % 2)) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f0f2ff' },
                    };
                }

                if (_rowNumber > 5) {
                    cell.border = {
                        top: { style: 'thin', color: { argb: 'C4CCFF' } },
                        left: { style: 'thin', color: { argb: 'C4CCFF' } },
                        bottom: { style: 'thin', color: { argb: 'C4CCFF' } },
                        right: { style: 'thin', color: { argb: 'C4CCFF' } },
                    };
                } else {
                    if (_rowNumber <= 6) {
                        if (
                            _colNumber === quizSummarySheet.columnCount ||
                            _colNumber === quizSummarySheet.columnCount - 1 ||
                            _colNumber === quizSummarySheet.columnCount - 2
                        ) {
                            cell.border = {
                                right: { style: 'thin', color: { argb: '6378FF' } },
                            };
                        } else
                            cell.border = {
                                top: { style: 'thin', color: { argb: '6378FF' } },
                                left: { style: 'thin', color: { argb: '6378FF' } },
                                bottom: { style: 'thin', color: { argb: '6378FF' } },
                                right: { style: 'thin', color: { argb: '6378FF' } },
                            };
                    }
                }
            });

            _rowNumber === 1 ? (row.height = 30) : (row.height = 24);
        });

        // Export Date & Time
        const exportDateRow = quizSummarySheet.getRow(
            quizSummarySheet.lastRow ? quizSummarySheet.lastRow?.number + 3 : 1,
        );
        const exportDateCell = quizSummarySheet.getCell(`A${exportDateRow.number}`);
        exportDateCell.value = `   ${templateText[lang].export_date}: ${displayLocaleDate(lang, new Date())}`;
        exportDateCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };

        // Cell Formatting & Merging
        const lastColumnAddress = quizSummarySheet.getRow(1).getCell(quizSummarySheet.columnCount).address;
        const lastColumnAddressLetter = lastColumnAddress.slice(0, lastColumnAddress.length - 1);

        const secondLastColumnAddress = quizSummarySheet.getRow(1).getCell(quizSummarySheet.columnCount - 1).address;
        const secondLastColumnAddressLetter = secondLastColumnAddress.slice(0, secondLastColumnAddress.length - 1);

        const thirdLastColumnAddress = quizSummarySheet.getRow(1).getCell(quizSummarySheet.columnCount - 2).address;
        const thirdLastColumnAddressLetter = thirdLastColumnAddress.slice(0, thirdLastColumnAddress.length - 1);

        const lastRowNumber = quizSummarySheet.lastRow?.number;

        // return
        quizSummarySheet.mergeCells(`A1:B1`);
        quizSummarySheet.mergeCells(`A2:B2`);
        quizSummarySheet.mergeCells(`A3:B3`);
        quizSummarySheet.mergeCells(`A4:B4`);
        quizSummarySheet.mergeCells(`A5:B5`);
        quizSummarySheet.mergeCells(`${secondLastColumnAddressLetter}1:${secondLastColumnAddressLetter}5`);
        quizSummarySheet.mergeCells(`${thirdLastColumnAddressLetter}1:${thirdLastColumnAddressLetter}5`);
        quizSummarySheet.mergeCells(`${lastColumnAddressLetter}1:${lastColumnAddressLetter}5`);
        quizSummarySheet.mergeCells(`A${lastRowNumber}:${lastColumnAddressLetter}${lastRowNumber}`);

        // Freeze top 5 Rows
        quizSummarySheet.views = [
            {
                state: 'frozen',
                ySplit: 5,
                showGridLines: false,
            },
        ];

        // Insert Image in Question Slides & Link Them
        await Promise.all(
            quizData.quizPropsArray.map(async (quizProps: any, index: number) => {
                const question = ExcelJSWorkbook.addWorksheet(`${templateText[lang].q}${index + 1}`);

                if (quizProps.activitySlideSavedUrl) {
                    const imageDimension: any = await findImageDimension(quizProps.activitySlideSavedUrl);

                    const imageID = ExcelJSWorkbook.addImage({
                        base64: imageDimension.dataURL,
                        extension: 'png',
                    });

                    question.addImage(imageID, {
                        tl: { col: 0, row: 2 },
                        ext: { width: 780, height: 780 / imageDimension.aspectRatio },
                        editAs: 'absolute',
                    });
                }

                // Interlinks
                question.getCell('A1').value = {
                    text: templateText[lang].go_back,
                    hyperlink: `#'${templateText[lang].quiz_summary}'!A1`,
                };
                question.getCell('A1').font = { underline: true, color: { argb: '0000FF' } };

                quizSummarySheet.eachRow(function (row: any, _rowNumber: any) {
                    row.eachCell(function (cell: any, _colNumber: any) {
                        if (_rowNumber === 1 && _colNumber === index + 3) {
                            cell.value = {
                                text: `${templateText[lang].q}${index + 1}`,
                                hyperlink: `#'${templateText[lang].q}${index + 1}'!A1`,
                            };
                            cell.font = { underline: true, color: { argb: '0000FF' } };
                        }
                    });
                });

                question.views = [
                    {
                        showGridLines: false,
                    },
                ];
            }),
        ).then(() => {
            ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    `${templateText[lang].quiz_summary}.xlsx`,
                );
                setIsDownloading(false);
            });
        });
    };

    return (
        <Fragment>
            <Header />
            {isDownloading ? <Downloading /> : !error ? <Downloaded /> : <DownloadError error={error} />}
        </Fragment>
    );
}

export default QuizSummary;
