import { Typography } from '@material-ui/core';
import { AppBar, Box, Toolbar } from '@mui/material';

function Header() {
    return (
        <div className="download_header">
            <div className="container">
                <AppBar position="static">
                    <Toolbar>
                        <Box className="box_align " sx={{ width: '100%' }}>
                            <div className="header_logo">
                                <img src={'/images/classpoint.svg'} alt="brand" />
                                <Typography>ClassPoint File Downloads</Typography>
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    );
}

export default Header;
