import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import Lottie from 'react-lottie-player';

function Downloaded() {
    return (
        <Fragment>
            <div className="download_body">
                <div className="container">
                    <div className="download_inner">
                        <div className="download_content">
                            <Lottie
                                loop={false}
                                animationData={require('../assets/completed.json')}
                                play
                                style={{ width: 18, height: 18 }}
                            />
                            <Typography variant="body1">Download complete</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Downloaded;
