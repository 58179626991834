import axios from 'axios';

const quizApi = {

    getMultipleActivities: async (activityIds: string) => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/multiple?activityIds=${activityIds}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            console.log('getMultipleActivities() error: ', error);
            return null;
        }
    }
};

export default quizApi;
