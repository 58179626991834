import React from 'react';
import Lottie from 'react-lottie-player';
import { Typography } from '@mui/material';

function DownloadError({ error }: { error: string }) {
    return (
        <div className="download_body">
            <div className="container">
                <div className="download_inner">
                    <div className="download_content">
                        <Lottie
                            loop={false}
                            animationData={require('../assets/failed.json')}
                            play
                            style={{ width: 18, height: 18 }}
                        />
                        <Typography variant="body1" className="red">
                            Download failed
                        </Typography>
                    </div>
                    <Typography variant="caption">{error}</Typography>
                </div>
            </div>
        </div>
    );
}

export default DownloadError;
