import DownLoadFile from './DownLoadFile/DownLoadFile';
import { Routes, Route } from 'react-router-dom';
import QuizSummary from './QuizSummary/QuizSummary';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/activity-files/:encryptCode" element={<DownLoadFile />} />
                <Route path="/quiz-summary/:encryptCode" element={<QuizSummary />} />
            </Routes>
        </div>
    );
}

export default App;
