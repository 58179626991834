export enum InterfaceLangs {
    ar = 'ar',
    zh = 'zh',
    // de = 'de',
    en = 'en',
    // es = 'es',
    fr = 'fr',
    // hi = 'hi',
    ja = 'ja',
    id = 'id',
    ms = 'ms',
    fil = 'fil',
    // pt = 'pt',
    // ru = 'ru',
    th = 'th',
    // tw = 'tw',
    vi = 'vi',
}

export const templateText = {
    ar: {
        quiz_summary: 'ملخص الاختبار',
        participant_name: 'الاسم',
        difficulty_level: 'مستوى الصعوبة',
        correct_answers: 'الاجابة الصحيحة',
        correct_percentage: 'النسبة الصحيحة',
        average_time_taken: 'متوسط الوقت المستغرق',
        q: 'Q',
        total_stars_earned: 'مجموع النجوم المكتسبة',
        score_percentage: '% النتيجة',
        full_marks: 'علامات كاملة',
        go_back: 'رجوع',
        export_date: 'تاريخ الحفظ',
        speed: 'السرعة',
        in_seconds: 'ثواني',
    },
    zh: {
        quiz_summary: '测试简报',
        participant_name: '姓名',
        difficulty_level: '难度',
        correct_answers: '正确答案',
        correct_percentage: '正确率',
        average_time_taken: '平均用时',
        q: '问题',
        total_stars_earned: '总星数',
        score_percentage: '得分率',
        full_marks: '总分',
        go_back: '返回',
        export_date: '报告生成日期',
        speed: '速度',
        in_seconds: '秒',
    },
    de: {},
    en: {
        quiz_summary: 'Quiz summary',
        participant_name: 'Name',
        difficulty_level: 'Difficulty level',
        correct_answers: 'Correct answer(s)',
        correct_percentage: 'Correct percentage',
        average_time_taken: 'Average time taken',
        q: 'Q',
        total_stars_earned: 'Total stars\nearned',
        score_percentage: 'Score %',
        full_marks: 'Full marks',
        go_back: 'Back',
        export_date: 'Export date',
        speed: 'Speed',
        in_seconds: 'seconds',
    },
    es: {},
    fr: {
        quiz_summary: 'Résumé du questionnaire',
        participant_name: 'Nom',
        difficulty_level: 'Niveau de difficulté',
        correct_answers: 'Réponses correcte(s)',
        correct_percentage: 'Pourcentage correct',
        average_time_taken: 'Temps moyen pris',
        q: 'Q',
        total_stars_earned: "Total d'étoiles gagnées",
        score_percentage: 'Score %',
        full_marks: 'Totalité des points',
        go_back: 'Retour',
        export_date: "Date d'exportation",
        speed: 'Rapidité',
        in_seconds: 'Secondes',
    },
    hi: {},
    ja: {
        quiz_summary: 'クイズの概要',
        participant_name: '名前',
        difficulty_level: '難易度',
        correct_answers: '正解',
        correct_percentage: '正确率',
        average_time_taken: '平均所要時間',
        q: '質問',
        total_stars_earned: '勝ち取る星の合計数',
        score_percentage: '得点率',
        full_marks: '総得点',
        go_back: '戻る',
        export_date: 'レポートの作成日',
        speed: 'スビート',
        in_seconds: '秒',
    },
    id: {
        quiz_summary: 'Quiz summary',
        participant_name: 'Nama',
        difficulty_level: 'Tingkat kesulitan',
        correct_answers: 'Jawaban benar',
        correct_percentage: 'Persentase Jawaban Benar',
        average_time_taken: 'Rata-rata waktu',
        q: 'Q',
        total_stars_earned: 'Total bintang',
        score_percentage: 'Skor %',
        full_marks: 'Total nilai',
        go_back: 'Kembali',
        export_date: 'Tanggal ekspor',
        speed: 'Kecepatan',
        in_seconds: 'Detik',
    },
    ms: {
        quiz_summary: 'Quiz summary',
        participant_name: 'Nama',
        difficulty_level: 'Tahap kesukaran',
        correct_answers: 'Jawapan benar',
        correct_percentage: 'Peratusan yang betul',
        average_time_taken: 'Purata masa',
        q: 'Q',
        total_stars_earned: 'Total bintang',
        score_percentage: 'Skor %',
        full_marks: 'Total markah',
        go_back: 'Kembali',
        export_date: 'Tanggal ekspor',
        speed: 'Kelajuan',
        in_seconds: 'Detik',
    },
    fil: {
        quiz_summary: 'Quiz summary',
        participant_name: 'Pangalan',
        difficulty_level: 'Lebel ng kahirapan',
        correct_answers: 'Tamang sagot',
        correct_percentage: 'Tamang porsyento',
        average_time_taken: 'Average na oras ng pagsagot',
        q: 'Q',
        total_stars_earned: 'Kabuuang stars',
        score_percentage: ' % ng puntos',
        full_marks: 'Kabuuang marka',
        go_back: 'Bumalik',
        export_date: 'Petsa ng pag-export',
        speed: 'Bilis',
        in_seconds: 'segundo',
    },
    pt: {},
    ru: {},
    th: {
        quiz_summary: 'Quiz summary',
        participant_name: 'ชื่อ',
        difficulty_level: 'ระดับความยาก',
        correct_answers: 'คำตอบที่ถูกต้อง',
        correct_percentage: 'เปอร์เซ็นต์ที่ถูกต้อง',
        average_time_taken: 'เวลาเฉลี่ย',
        q: 'Q',
        total_stars_earned: 'จำนวนดาวที่ได้รับ',
        score_percentage: 'เปอร์เซ้นต์คะแนน',
        full_marks: 'คะแนนเต็ม',
        go_back: 'ก่อนหน้า',
        export_date: 'วันที่ส่งออกข้อมูล',
        speed: 'ความเร็ว',
        in_seconds: 'วินาที',
    },
    tw: {},
    vi: {
        quiz_summary: 'Tổng hợp kết quả Quiz',
        participant_name: 'Tên',
        difficulty_level: 'Độ khó',
        correct_answers: 'Đáp án',
        correct_percentage: 'Tỷ lệ đúng',
        average_time_taken: 'Thời gian trả lời trung bình',
        q: 'C',
        total_stars_earned: 'Tổng số Sao đã giành được',
        score_percentage: '% ghi điểm',
        full_marks: 'Tổng điểm thiết lập',
        go_back: 'Quay lại',
        export_date: 'Ngày xuất file',
        speed: 'Tốc độ',
        in_seconds: 'giây',
    },
};

export const displayLocaleDate = (lang: string, date: Date) => {
    let expiryDate = date.toLocaleDateString(chooseDateFormat(lang), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    return expiryDate;
};

const chooseDateFormat = (language: string) => {
    switch (language) {
        case 'ar':
            return 'ar-AA';
        case 'zh':
            return 'zh-CN';
        case 'de':
            return 'de-DE';
        case 'en':
            return 'en-GB';
        case 'es':
            return 'es-ES';
        case 'fr':
            return 'fr-FR';
        case 'hi':
            return 'hi-IN';
        case 'id':
            return 'id-ID';
        case 'ja':
            return 'ja-JP';
        case 'ms':
            return 'ms-MY';
        case 'fil':
            return 'tl-PH';
        case 'pt':
            return 'pt-PT';
        case 'ru':
            return 'ru-RU';
        case 'th':
            return 'th-TH';
        case 'vi':
            return 'vi-VN';
        default:
            return 'en-GB';
    }
};
