import _ from 'lodash';
import QuizActivityDataInterface from '../interfaces/quiz-activity-data.interface';
import QuizActivityPropsInterface from '../interfaces/quiz-activity-props.interface';

export interface QuizSummaryResponseInterface {
    answer: string;
    isCorrect: boolean;
    timeTaken: number;
    quizPoints: number;
}
export interface QuizSummaryParticipantInterface {
    rank: number;
    participantId: string;
    participantName: string;
    participantAvatar: string;
    participationCount: number;
    correctCount: number;
    averageTimeTaken: number;
    totalQuizPoints: number;
    responses: (QuizSummaryResponseInterface | null)[];
}

export interface QuizSummaryInterface {
    quizPropsArray: QuizActivityPropsInterface[];
    rankedParticipants: QuizSummaryParticipantInterface[];
}

export const populateRankedParticipants = (quizDataArray: QuizActivityDataInterface[]) => {
    const quizSummaryParticipants: QuizSummaryParticipantInterface[] = [];
    // generate leaderboard template
    for (let index = 0; index < quizDataArray.length; index++) {
        if (quizDataArray[index].activityResponses)
            quizDataArray[index].activityResponses.forEach((response) => {
                if (
                    !quizSummaryParticipants.find((participant) => participant.participantId === response.participantId)
                ) {
                    quizSummaryParticipants.push({
                        rank: 0,
                        participantId: response.participantId,
                        participantName: response.participantName,
                        participantAvatar: response.participantAvatar,
                        participationCount: 0,
                        correctCount: 0,
                        averageTimeTaken: 0,
                        totalQuizPoints: 0,
                        responses: Array.from(Array(quizDataArray.length), (x) => null),
                    });
                }
            });
    }

    // fill in leaderboard data
    for (let index = 0; index < quizDataArray.length; index++) {
        const quizActivity = quizDataArray[index];
        let correctCount = 0;
        if (quizActivity.activityResponses) {
            quizActivity.activityResponses.forEach((response) => {
                const correctAnswers = quizActivity.mcCorrectAnswers;
                if (!correctAnswers) return;

                const participantIndex = quizSummaryParticipants.findIndex(
                    (participant) => participant.participantId === response.participantId,
                );
                const timeTaken =
                    (new Date(response.responseSubmittedOn).getTime() -
                        new Date(quizActivity.activityCreatedOn).getTime()) /
                    1000;
                const isCorrect = JSON.parse(response.responseData).join(',') === correctAnswers.join(',');
                quizSummaryParticipants[participantIndex].responses[index] = {
                    answer: JSON.parse(response.responseData).join(','),
                    isCorrect,
                    timeTaken,
                    quizPoints: (quizActivity.correctPoints || 0) + (response.correctSpeedBonus || 0),
                };
                if (isCorrect) correctCount = correctCount + 1;
            });

            const correctPercentage = ((correctCount / quizActivity.activityResponses.length) * 100).toFixed(2);
            quizActivity.correctPercentage = Number(correctPercentage);
        } else quizActivity.correctPercentage = 0;
    }

    // update participation count and correct count
    if (quizSummaryParticipants.length > 0)
        for (let participant of quizSummaryParticipants) {
            const participatedResponses = participant.responses.filter((response) => !!response);
            const participationCount = participatedResponses.length;
            const correctCount = participatedResponses.filter((r: any) => r.isCorrect).length;
            const totalTimeTaken = participatedResponses
                .filter((r: any) => r.isCorrect)
                .map((r: any) => r.timeTaken)
                .reduce((a: number, b: number) => a + b, 0);
            const totalQuizPoints = participatedResponses
                .filter((r: any) => r.isCorrect)
                .map((r: any) => r.quizPoints)
                .reduce((a: number, b: number) => a + b, 0);
            participant.participationCount = participationCount;
            participant.correctCount = correctCount;
            if (correctCount > 0) participant.averageTimeTaken = Math.round((totalTimeTaken / correctCount) * 10) / 10;
            participant.totalQuizPoints = totalQuizPoints;
        }

    // const rankedParticipants = _.orderBy(quizSummaryParticipants, ['correctCount', 'averageTimeTaken'], ['desc', 'asc']);
    const rankedParticipants = _.orderBy(
        quizSummaryParticipants,
        // ['totalQuizPoints', 'correctCount', 'participantName'],
        ['totalQuizPoints', 'averageTimeTaken', 'participantName'],
        ['desc', 'asc', 'asc'],
    );

    // option A: participants with same stars will have same rank
    // const uniqArrayByPoints = _.uniq(rankedParticipants.map((p) => p.totalQuizPoints));
    // rankedParticipants.forEach((p) => (p.rank = uniqArrayByPoints.indexOf(p.totalQuizPoints) + 1));
    // option B: take time into consideration
    rankedParticipants.forEach((p) => (p.rank = rankedParticipants.indexOf(p) + 1));

    let quizPropsResult: QuizActivityPropsInterface[] = [];
    quizDataArray.forEach((activity) => {
        quizPropsResult.push({
            activityId: activity.activityId,
            activitySlideSavedUrl: activity.activitySlideSavedUrl,
            mcCorrectAnswers: activity.mcCorrectAnswers || [],
            correctPoints: activity.correctPoints || 0,
            correctPercentage: activity.correctPercentage,
        });
    });

    const quizSummary: QuizSummaryInterface = {
        quizPropsArray: quizPropsResult,
        rankedParticipants: rankedParticipants,
    };

    return quizSummary;
};
