import axios from 'axios';

interface ActivityResponseInterface {
    responseId: string;
    participantId: string;
    participantName: string;
    participantAvatar: string;
    responseData: string;
    responsePoints: number;
    correctPoints?: number;
    correctSpeedBonus?: number;
    voterParticipantIds?: string[];
    responseSubmittedOn: Date;
}

export interface FileInterface {
    fileName: string;
    url: string;
    isImageUploadActivity?: boolean;
}

export const findImageExtensionFromUrl = (imageUrl: string): string => {
    const acceptedExts = ['.jpg', '.jpeg', '.png', '.gif', '.tif', '.tiff', '.bmp', '.eps'];
    for (let ext of acceptedExts) {
        if (imageUrl.includes(ext)) return '';
    }
    return '.jpg';
};

export const generateActivityFiles = async (activityId: string, indexes: string): Promise<FileInterface[]> => {
    let activity: any = null;
    for (let i = 0; i < 3; i++) {
        activity = await getActivity(activityId);
        if (activity) break;
    }
    if (!activity) return [];

    const indexArray = indexes.split(',');
    let fileArray: FileInterface[] = [];
    indexArray.forEach((index) => {
        const response: ActivityResponseInterface = activity.activityResponses[parseInt(index)];
        if (response) {
            const activityType = activity.activityType;
            const url = isJson(response.responseData) ? JSON.parse(response.responseData)[0] : response.responseData;
            const isVideoActivity = activityId.startsWith('vu');
            let fileName = `${response.participantName}-${url.substring(url.length - 7)}${
                isVideoActivity ? '.mp4' : ''
            }`;
            let isImageUploadActivity = false;
            if (activityType === 'Image Upload') {
                const fileExt = findImageExtensionFromUrl(url);
                fileName = `${fileName}${fileExt}`;
                isImageUploadActivity = true;
            }
            fileArray.push({
                fileName,
                url,
                isImageUploadActivity,
            });
        }
    });

    return fileArray;
};

const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const getActivity = async (activityId: string) => {
    try {
        const url = `${process.env.REACT_APP_APITWO}/activities?activityId=${activityId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error: any) {
        console.log('getActivity() error: ', error.response || error);
        return null;
    }
};

export const checkUrlExistInFileApp = (fileUrl: string): boolean => {
    const fileAppBaseUrl = process.env.REACT_APP_CPFILE_BASE_URL;
    if (fileAppBaseUrl && fileUrl.split('/').length > 0) {
        return fileUrl.split('/')[2].includes(fileAppBaseUrl);
    } else return false;
};

export const uploadFileUrlToStorage = async (fileUrl: string): Promise<string> => {
    const custom_file_upload_url = `${process.env.REACT_APP_FILEAPP}/add-by-url/cpfile/user`;
    try {
        const response = await axios.post(custom_file_upload_url, {
            sourceUrl: fileUrl,
        });
        return response.data.fileUrl;
    } catch (error: any) {
        console.log('uploadFileUrl() error: ', error.response || error);
        return '';
    }
};
