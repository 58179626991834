import { Typography } from '@mui/material';
import { Fragment } from 'react';
import Lottie from 'react-lottie-player';

function Downloading() {
    return (
        <Fragment>
            <div className="download_body">
                <div className="container">
                    <div className="download_inner">
                        <div className="download_content">
                            <Lottie
                                loop
                                animationData={require('../assets/downloading.json')}
                                play
                                style={{ width: 18, height: 18 }}
                            />
                            <Typography variant="body1" className="">
                                Downloading files...
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Downloading;
